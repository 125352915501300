/* eslint-disable camelcase */
import React from 'react'
import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Billboard,
  CtaBar,
  LeshenPhoneCTA,
  Image,
  LinkButton,
  Stack,
  Column,
  Columns,
  List,
  ListItem,
  AvailabilityForm,
  VariableContent,
  SplitContent,
  SVGImage,
} from '@leshen/gatsby-theme-leshen'

import { Typography } from '@leshen/ui'
import { graphql, Link as GatsbyLink } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const CityTemplate = ({ data }) => {
  const { Page_Path, State, City, Promo_Code, Meta_Description, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  const heroDesktopData = {
    ...data.heroDesktop?.cloudinary[0]?.gatsbyImageData,
    context: data.heroDesktop?.cloudinary[0]?.context,
  }

  const heroMobileData = {
    ...data.heroMobile?.cloudinary[0]?.gatsbyImageData,
    context: data.heroMobile?.cloudinary[0]?.context,
  }

  const homePhoneData = {
    ...data.homePhone?.cloudinary[0]?.gatsbyImageData,
    context: data.homePhone?.cloudinary[0]?.context,
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            image={<Image data={heroDesktopData} isImageCritical />}
            mobileImage={<Image data={heroMobileData} isImageCritical />}
            mainContent={
              <>
                <Typography>
                  <strong>
                    Great news, CenturyLink is available in your area.
                  </strong>{' '}
                  Find deals on internet, home phone, and TV packages below.
                </Typography>
                <Typography variant="h1">
                  Confirmed: CenturyLink available in {City}, {State_Name}
                </Typography>
                <AvailabilityForm
                  label="Try another ZIP code"
                  placeholderText="Enter ZIP Code"
                  buttonText="Check Availability"
                  buttonColor="primary"
                />
              </>
            }
          />
          <CtaBar
            backgroundColor="secondary"
            textColor=""
            sticky
            leftBlock={<LeshenPhoneCTA color="white">Call Now</LeshenPhoneCTA>}
            middleBlock={
              <LinkButton color="white" to="/cart">
                Build Your Plan
              </LinkButton>
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            image={
              <SVGImage
                svg={data.manOnCouch.cloudinary[0].svg.code}
                presentationWidth={
                  data.manOnCouch.cloudinary[0].svg.presentationWidth
                }
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Why choose CenturyLink internet service in your area?
                </Typography>
                <Typography variant="h4">
                  If speed is just one of the things you value.
                </Typography>
                <Typography>
                  CenturyLink availability helps you achieve the digital
                  lifestyle you want: first by plugging you in to high speeds,
                  and second by offering you support, flexibility, and freedom
                  in how you use your CenturyLink internet service.
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        <strong>Month-to-month</strong> billing with no
                        cancellation fees
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        <strong>Unlimited data</strong> with no extra fees
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        <strong>24/7 technical support</strong> to fit your
                        unique schedule
                      </Typography>
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <VariableContent
            backgroundColor="Light"
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink availability comes with TV options
                </Typography>
                <Typography variant="h4">
                  Third-party TV plans to rival any streaming service.
                </Typography>
                <Typography>
                  When you order CenturyLink in your area, you have the option
                  to add TV packages from partner providers DISH and DIRECTV.
                  Both offer hundreds of live channels, DVR storage, vast On
                  Demand libraries, mobile viewing, and smart features including
                  voice search on compatible devices.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false} cardStyled>
                <Column>
                  <Typography variant="h4">DISH TV</Typography>
                  <List>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          3-year TV price guarantee
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          On Demand titles and mobile viewing
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          CenturyLink places your order
                        </Typography>
                      </Typography>
                    </ListItem>
                  </List>
                  <br />
                  <br />
                  <br />
                  <LinkButton
                    to="/tv/dish-tv"
                    CustomLink={GatsbyLink}
                    color="primary"
                  >
                    Learn More About DISH
                  </LinkButton>
                </Column>
                <Column>
                  <Typography variant="h4">DIRECTV</Typography>
                  <List>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          2-year TV price guarantee
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          On Demand titles and mobile viewing
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          CenturyLink coordinates your order
                        </Typography>
                      </Typography>
                    </ListItem>
                  </List>
                  <br />
                  <br />
                  <br />
                  <LinkButton
                    to="/tv/directv"
                    CustomLink={GatsbyLink}
                    color="primary"
                  >
                    Learn More About DIRECTV
                  </LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <SplitContent
            customLink={GatsbyLink}
            image={<Image data={homePhoneData} isImageCritical />}
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink home phone in {City}
                </Typography>
                <Typography variant="h4">
                  Save $10/mo with CenturyLink bundles.
                </Typography>
                <Typography>
                  Dial into the {City} grid with CenturyLink home phone. Whether
                  you choose landline service for the nostalgia, savings, or
                  emergency prep, CenturyLink home phone offers the same
                  benefits: crystal-clear voice signal, advanced features, and
                  cost savings.
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        $10/mo savings with CenturyLink bundles
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Unlimited Nationwide Calling
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Voicemail, call waiting, call forwarding
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Fast location detection by first responders
                      </Typography>
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LinkButton
                  to="/home-phone"
                  CustomLink={GatsbyLink}
                  color="primary"
                >
                  Learn More About Phone Plans
                </LinkButton>
              </>
            }
          />
        </>
      }
    />
  )
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery($pagePath: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerCenturylinkquoteAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          Page_Path
          State
          City
          Promo_Code
          Meta_Description
          Meta_Title
        }
      }
    }
    heroDesktop: contentfulMedia(
      contentful_id: { eq: "2wzlh403cNCMEx66zAHngz" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroMobile: contentfulMedia(
      contentful_id: { eq: "27TyRVfQeL09Hkg8M4eGKI" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    manOnCouch: contentfulMedia(
      contentful_id: { eq: "5Lzgvxoc3Nk3nHf8AJfHXi" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    homePhone: contentfulMedia(
      contentful_id: { eq: "6zyyiw4BGfuAR6NyXSCXp5" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    footerDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-allrightsreserved" }
    ) {
      symbol
      text
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "082a6816-17e1-5fb3-9e89-55181e05011f" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "0ef79f94-dee9-5fbe-81be-670c6a1983eb" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
